import React from 'react';
import * as styles from './ort.module.scss';

import Layout from "../page-components/layout/layout"
import { graphql } from "gatsby"
import RecordCard from "../page-components/recordCard"
import { GatsbyImage } from "gatsby-plugin-image"

import ChevronRightSVG from "../../icons/fontawesome/light/chevron-right.svg";
import OrtSimilar from "./ortSimilar"

const OrtTemplate = ( { data } ) => {

  const node = data.allWpStadt.nodes[0];
  const records = data.allWpRecord.nodes.filter(
    n => {
      if (!n.acf?.stadt) {
        return false;
      }

      for (const nn of n.acf.stadt) {

        if (nn.title.toLowerCase().indexOf(node.title.toLowerCase()) !== -1) {
          return true;
        }
      }

      return false;
    }
  );

  return <Layout>

    <div className={styles.teaserWrapper}>

        {
          node.acf_stadt.teaser?.localFile &&
          <GatsbyImage alt={"Teaser " + node.title} image={node.acf_stadt.teaser.localFile.childImageSharp.gatsbyImageData}
                       className={styles.teaser} />
        }

        <div className={styles.teaserContent}>
          {node.acf_stadt.teaserinfo &&
            <sub>{node.acf_stadt.teaserinfo}</sub>
          }
        </div>

    </div>

    <div className="bg-primary-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-12 py-4 d-flex justify-content-between align-items-center">
            <h1>{node.title}</h1>

            { node.acf_stadt.homepage &&
            <a className={"d-flex text-white text-decoration-none align-items-center c-pointer " + styles.ortHomepageLink}
               href={node.acf_stadt.homepage}
               target={"_blank"}
            >
              <ChevronRightSVG className={"svgFill me-1"} />
              Webseite des Ortes
            </a>
            }

          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="row pt-4 pb-5">
        {
          records
            .filter(
              r => {
                const result = !!r.acf.teaser?.localFile?.url
                if (!result) {

                }
                return result;
              }
            )
            .map(
              r => <div key={r.id} className={"col-lg-4 mt-3 " + styles.record}>
                <RecordCard record={r} />
              </div>
            )
          }

        {records.length === 0 &&
          <div className={"col-12 mt-4"}>
            <p>Die Aufnahmen von {node.title} stehen in Kürze zur Verfügung!</p>
          </div>
        }


      </div>
    </div>

    <OrtSimilar />

  </Layout>

};

export default OrtTemplate;

export const query = graphql`
query StadtDetail($id: String!) {
    allWpStadt(filter: {id: {eq: $id}}) {
      nodes {
        
        acf_stadt {
        
          place {
            longitude
            latitude
          }
          teaser {
            id
            localFile {
              url
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          
          teaserinfo
          homepage
        }
        
        databaseId
        content
        id
        slug
        title
    }
  }
  
  allWpRecord {
      nodes {
        databaseId
        id
        slug
        title
        acf {
          stadt {
            ... on WpStadt {
              id
              title
            }
          }
          teaser {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(aspectRatio: 2)
              }
            }
          }
      }
  }
  }

  
}`;

