import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import * as styles from "./ort.module.scss"
import RecordCard from "../page-components/recordCard"

const OrtSimilar = () => {
  const data = useStaticQuery(graphql`
    query OrtSimilarQuery {
      allWpRecord {
        nodes {
          databaseId
          id
          slug
          title
          acf {
            stadt {
              ... on WpStadt {
                id
                title
              }
            }
            teaser {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(aspectRatio: 2)
                }
              }
          }
        }
      }
    }
  }
  `);

  return   <div className="bg-gray">
    <div className="container py-4">

      <div className="row">

        <div className="col-12">
          <h2 className="opacity-75 fw-light">Das könnte Sie auch interessieren</h2>
        </div>


      {
        data?.allWpRecord.nodes?.sort( (a, b) => .5 - Math.random() ).
        filter(
            (r, i) => {
              const result = !!r.acf.teaser?.localFile?.url

              if (result && i < 3) {
                return true;
              }

              return false;
            }
          )
          .map(
            r => <div key={r.id} className={"col-lg-4 mt-3 " + styles.record}>
              <RecordCard showStadt={true} record={r} />
            </div>
          )
      }

      </div>
    </div>
  </div>;


};

export default OrtSimilar;
